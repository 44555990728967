import { argon2id } from "hash-wasm";

export async function HashArgon2idImpl(
  password: Uint8Array,
  salt: Uint8Array,
  iterations: number,
  memorySize: number,
  hashLength: number,
): Promise<Uint8Array> {
  return await argon2id({
    password: password,
    salt: salt,
    iterations: iterations,
    memorySize: memorySize,
    hashLength: hashLength,
    parallelism: 1,
    outputType: "binary",
  });
}
