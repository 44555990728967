import * as impl from "#argon2-impl";

export async function hashArgon2id(
  password: Uint8Array,
  salt: Uint8Array,
  iterations: number,
  memorySize: number,
  hashLength: number,
): Promise<Uint8Array> {
  return await impl.HashArgon2idImpl(
    password,
    salt,
    iterations,
    memorySize,
    hashLength,
  );
}
